// @flow

import styles from "./NavigationContainer.module.css";
import React, { useState } from "react";
import type { Node } from "react";
import {
  Navbar,
  Alignment,
  Button,
  Classes,
  NavbarDivider,
  NavbarGroup,
  NavbarHeading,
  Intent,
} from "@blueprintjs/core";
import Flexbox from "flexbox-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faArrowLeft, faHome } from "@fortawesome/free-solid-svg-icons";
import history from "../../history";
import NavigationDrawer from "../NavigationDrawer/NavigationDrawer";
import PageRedirectConfirmPopup from "./ConfirmationAlert"

type Props = {
  children?: Node,
  viewTitle: string,
  viewSubTitle?: string,
  showBackButton?: boolean,
  logoUrl: string,
};

export default function NavigationContainer(props: Props) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [showLogo, setShowLogo] = useState(false);
  const [showConfirmationPopup, setConfirmationPopup] = useState(false);

  const confirmPopup = (
    <PageRedirectConfirmPopup
      isOpen={showConfirmationPopup ? true : false}
      cancelButtonText="Cancel"
      confirmButtonText="Continue"
      icon="confirm"
      type={Intent.WARNING}
      onCancel={() => setConfirmationPopup(null)}
      onConfirm={async () => {
        history.push("/app/");
        setConfirmationPopup(null);
      }}
      message="Unsaved changes will be lost!  Are you sure you want to return home?"
    />
  );

  const formPaths = ['create-project', 'update-project', 'create-vendor', 'create-task', 'update-task', 'brand',
    'create-project-group', 'update-project-group', 'update-vendor', 'update-user'];

  function redirectToHome() {
    console.log(window.location.pathname.split('/'));
    let formPath;
    window.location.pathname.split('/').forEach((path, index) => {
      if (formPaths.includes(path)) {
        formPath = path
        //console.log("path", path);
        //setConfirmationPopup(true);
        console.log(index);
        return;
      }
    })
    if (formPath) {
      console.log("path", formPath);
      setConfirmationPopup(true);
    } else {
      console.log("/app/");
      history.push("/app/");
    }
  }
  return (
    <Flexbox flexDirection="column" minHeight="100vh" width="100vw">
      <Flexbox element="header">
        <Navbar className={styles.navBar} fixedToTop>
          <NavbarGroup align={Alignment.LEFT} className={styles.navBarLeft}>
            {props.showBackButton && (
              <Button
                className={`${Classes.MINIMAL}`}
                onClick={() => {
                  history.goBack();
                }}
                icon={
                  <FontAwesomeIcon
                    icon={faArrowLeft}
                    color="white"
                    size={"lg"}
                  />
                }
              />
            )}
            {props.logoUrl ? (
              <img
                style={{ height: 45, marginLeft: 16 }}
                src={props.logoUrl}
                alt={"Logo"}
                onError={() => {
                  setShowLogo(false);
                }}
              />
            ) : null}
          </NavbarGroup>
          <NavbarGroup align={Alignment.CENTER} className={styles.navBarCenter}>
            <NavbarHeading className={styles.navBarCenterHeader}>
              <Flexbox
                width={"100%"}
                justifyContent={"center"}
                flexDirection={"column"}
              >
                <h4
                  className={`bp3-text-overflow-ellipsis ${styles.titleText}`}
                >
                  {props.viewTitle}
                </h4>
                {props.viewSubTitle ? (
                  <h4
                    className={`bp3-text-overflow-ellipsis ${styles.titleText}`}
                  >
                    {props.viewSubTitle}
                  </h4>
                ) : null}
              </Flexbox>
            </NavbarHeading>
          </NavbarGroup>
          <NavbarGroup align={Alignment.RIGHT} className={styles.navBarRight}>
            <Button
              className={`${Classes.MINIMAL}`}
              icon={
                <FontAwesomeIcon
                  icon={faHome}
                  color="white"
                  size={"lg"}
                  onClick={() => {
                    redirectToHome();
                  }}
                />
              }
            />
            <NavbarDivider />
            <Button
              className={`${Classes.MINIMAL}`}
              icon={
                <FontAwesomeIcon
                  icon={faBars}
                  color="white"
                  size={"lg"}
                  onClick={() => {
                    setIsDrawerOpen(true);
                  }}
                />
              }
            />
          </NavbarGroup>
        </Navbar>
      </Flexbox>
      <Flexbox
        flexGrow={1}
        flexDirection={"column"}
        width={"100%"}
        height={"100%"}
      >
        <NavigationDrawer
          isOpen={isDrawerOpen}
          onClose={() => {
            setIsDrawerOpen(false);
          }}
        />
        <span className={`bp3-navbar ${styles.navBarSpacer}`} />
        {props.children}
      </Flexbox>
      {confirmPopup}
    </Flexbox>
  );
}
